import React, { useState } from "react";
import "./AboutContent.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import AboutBoxes from "../../Common/Hero/AboutBoxes";
import ServicesSection from "../../Common/Hero/ServicesSection";
import TeamSection from "../../Common/Hero/TeamSection";

const AboutContent = () => {
  const [activeTab, setActiveTab] = useState("History");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div>
      <Header />
      <AboutBoxes />
      <ServicesSection />
      <TeamSection />
      <Footer />
    </div>
  );
};

export default AboutContent;
