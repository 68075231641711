import React, { useState, useEffect } from "react";
import "./HeroSection.css";
import { motion } from "framer-motion";
import { PiHandCoinsFill } from "react-icons/pi";
import { MdEventAvailable, MdMenuBook } from "react-icons/md";
import { FaTrophy } from "react-icons/fa";
import { MdArrowOutward } from "react-icons/md";

const HeroSection = () => {
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 400) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <section id="hero">
        <div class="hero-container" data-aos="fade-up" data-aos-delay="150">
          <div className="col">
            <div className="Headings row justify-content-start">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <h3>
                  We provide <b>customized learning solutions</b> focused
                  towards
                </h3>
                <h1>
                  Empowering <span className="highlight-color3"> Mind's, </span>
                  Transforming Cultures
                </h1>
              </div>
              <div className="banner-img col-sm-12 col-md-6 col-lg-6 justify-content-start">
                {/* <img src="assets/hand.png" alt="push" /> */}
                <iframe
                  src="https://lottie.host/embed/7d949b01-c85f-4e77-b8eb-959f4caaa98b/VHFt5NCZPP.json"
                  // src="https://lottie.host/embed/8260d769-2ba3-4045-9a05-045a253c7260/qRLBWEw13u.json"
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "none",
                  }}
                  title="Animation"
                ></iframe>
              </div>
            </div>
            <div className="row justify-content-center align-item-center">
              <h2>
                Explore our new-age, Gamified & immersive learning solutions to
                create a lasting impact.
              </h2>
              <div className="col-6 d-flex justify-content-center ">
                <div className="b-btn">
                  <span className="b-btn-name">Organizations</span>
                  <a href="/org" className="button-hero  glightbox">
                    Learn more
                    <span>
                      <MdArrowOutward />
                    </span>
                  </a>
                </div>
              </div>
              <div className="col-6 d-flex justify-content-center ">
                <div class="b-btn">
                  <span class="b-btn-name">Individuals</span>
                  <a href="/org" class="button-hero glightbox">
                    Learn more
                    <span>
                      <MdArrowOutward />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="floating-links">
        <div className="float-container">
          <a href="/home" className={scrolled ? "scrolled" : ""}>
            <i>
              <MdEventAvailable />
            </i>
            Upcoming Events
          </a>
          <a href="/home" className={scrolled ? "scrolled" : ""}>
            <i>
              <MdMenuBook />
            </i>
            New Courses
          </a>
          <a href="/home" className={scrolled ? "scrolled" : ""}>
            <i>
              <FaTrophy />
            </i>
            Latest Blog
          </a>
          <a href="/home" className={scrolled ? "scrolled" : ""}>
            <i>
              <PiHandCoinsFill />
            </i>
            Claim Your Reward
          </a>
        </div>
      </section>
    </div>
  );
};

export default HeroSection;
