import React from "react";
import "./CallSection.css";
const CallSection = () => {
  return (
    <div>
      <section id="call-to-action" className="call-to-action section mt-5">
        <img src="assets/img/cta-bg.jpg" alt />
        <div className="container">
          <div
            className="row justify-content-center"
            data-aos="zoom-in"
            data-aos-delay={100}
          >
            <div className="col-xl-10">
              <div className="text-center">
                <h3>Call To Action</h3>
                <p>
                  Bridge the Skills Gap Like Never Before. Tailored Training
                  Programs Drive Measurable Business Growth.
                </p>

                <a className="cta-btn" href="#">
                  Call To Action
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CallSection;
