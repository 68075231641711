import React from "react";
import "./TeamSection.css";
import { Border } from "react-bootstrap-icons";
const TeamSection = () => {
  return (
    <div>
      <section id="team" className="team section pt-5">
        <div className="container">
          <div className="section-title">
            <h2>team</h2>
            <p>Meet our team</p>
          </div>
          <div className=" row justify-content-center">
            <p>
              Our talented team combines expertise and innovation, working
              together to achieve exceptional results. Meet the people driving
              our success.
            </p>
            <div
              className="col-lg-3 col-md-6 d-flex"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              <div className="member">
                <img
                  src="assets/img/team/Bindu_Bhatia.jpg"
                  className="img-fluid"
                  alt="md/founder"
                />
                <div className="member-content">
                  <h4>BINDU BHATIA</h4>
                  <span>MD & FOUNDER</span>
                  {/* <p>
                    Magni qui quod omnis unde et eos fuga et exercitationem.
                    Odio veritatis perspiciatis quaerat qui aut aut aut
                  </p> */}
                  <div className="social">
                    <a href>
                      <i className="ri-twitter-fill"></i>
                    </a>
                    <a href>
                      <i className="ri-facebook-fill"></i>
                    </a>
                    <a href>
                      <i className="ri-instagram-line"></i>
                    </a>
                    <a href>
                      <i className="ri-linkedin-fill"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* End Team Member */}
            <div
              className="col-lg-3 col-md-6 d-flex"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="member">
                <img
                  src="assets/img/team/Harish_Bhatia.jpg"
                  className="img-fluid"
                  alt
                />
                <div className="member-content">
                  <h4>HARISH BHATIA</h4>
                  <span>Sr. Business Consultant & Executive Coach</span>
                  {/* <p>
                    Repellat fugiat adipisci nemo illum nesciunt voluptas
                    repellendus. In architecto rerum rerum temporibus
                  </p> */}
                  <div className="social">
                    <a href>
                      {" "}
                      <i className="ri-twitter-fill"></i>
                    </a>
                    <a href>
                      <i className="ri-facebook-fill"></i>
                    </a>
                    <a href>
                      <i className="ri-instagram-line"></i>
                    </a>
                    <a href>
                      <i className="ri-linkedin-fill"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* End Team Member */}

            <div
              className="col-lg-3 col-md-6 d-flex"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              <div className="member">
                <img
                  src="assets/img/team/Kavisha.jpg"
                  className="img-fluid"
                  alt="md/founder"
                />
                <div className="member-content">
                  <h4>KAVISHA MANWANI</h4>
                  <span>Lead | Operations Strategy </span>
                  {/* <p>
                    Magni qui quod omnis unde et eos fuga et exercitationem.
                    Odio veritatis perspiciatis quaerat qui aut aut aut
                  </p> */}
                  <div className="social">
                    <a href>
                      <i className="ri-twitter-fill"></i>
                    </a>
                    <a href>
                      <i className="ri-facebook-fill"></i>
                    </a>
                    <a href>
                      <i className="ri-instagram-line"></i>
                    </a>
                    <a href>
                      <i className="ri-linkedin-fill"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* End Team Member */}
            <div
              className="col-lg-3 col-md-6 d-flex"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="member">
                <img
                  src="assets/img/team/padmini.jpg"
                  className="img-fluid"
                  alt
                />
                <div className="member-content">
                  <h4>PADMINI BHASIN</h4>
                  <span>Lead | Content Strategy & Knowledge Management</span>
                  {/* <p>
                    Repellat fugiat adipisci nemo illum nesciunt voluptas
                    repellendus. In architecto rerum rerum temporibus
                  </p> */}
                  <div className="social">
                    <a href>
                      {" "}
                      <i className="ri-twitter-fill"></i>
                    </a>
                    <a href>
                      <i className="ri-facebook-fill"></i>
                    </a>
                    <a href>
                      <i className="ri-instagram-line"></i>
                    </a>
                    <a href>
                      <i className="ri-linkedin-fill"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* End Team Member */}
          
          </div>
        </div>
      </section>
    </div>
  );
};

export default TeamSection;
