import React, { useState, useEffect } from "react";
import "./ServicesSection.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLaptop,
  faChartBar,
  faLightbulb,
  faBriefcase,
  faCheckSquare,
  faClock,
} from "@fortawesome/free-solid-svg-icons";

const ServicesSection = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [slidesPerView, setSlidesPerView] = useState(0);
  const [expanded, setExpanded] = useState([]);

  const [activeTab, setActiveTab] = useState("tab-1");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div id="ServiceSection" className="mt-5">
      <section id="features" className="features mt-5 mb-5">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Services</h2>
            <p>Check our Services</p>
          </div>
          <ul className="nav nav-tabs row d-flex">
            <li className="nav-item col-3">
              <a
                className={`nav-link ${
                  activeTab === "tab-1" ? "active show" : ""
                }`}
                onClick={() => handleTabClick("tab-1")}
              >
                <i className="ri-gps-line"></i>
                <h4 className="d-none d-lg-block">
                  Modi sit est dela pireda nest
                </h4>
              </a>
            </li>
            <li className="nav-item col-3">
              <a
                className={`nav-link ${
                  activeTab === "tab-2" ? "active show" : ""
                }`}
                onClick={() => handleTabClick("tab-2")}
              >
                <i className="ri-body-scan-line"></i>
                <h4 className="d-none d-lg-block">
                  Unde praesenti mara setra le
                </h4>
              </a>
            </li>
            <li className="nav-item col-3">
              <a
                className={`nav-link ${
                  activeTab === "tab-3" ? "active show" : ""
                }`}
                onClick={() => handleTabClick("tab-3")}
              >
                <i className="ri-sun-line"></i>
                <h4 className="d-none d-lg-block">
                  Pariatur explica nitro dela
                </h4>
              </a>
            </li>
            <li className="nav-item col-3">
              <a
                className={`nav-link ${
                  activeTab === "tab-4" ? "active show" : ""
                }`}
                onClick={() => handleTabClick("tab-4")}
              >
                <i className="ri-store-line"></i>
                <h4 className="d-none d-lg-block">Nostrum qui dile node</h4>
              </a>
            </li>
          </ul>

          <div className="tab-content">
            <div
              className={`tab-pane ${
                activeTab === "tab-1" ? "active show" : ""
              }`}
              id="tab-1"
            >
              <div className="row">
                <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                  <h3>
                    Voluptatem dignissimos provident quasi corporis voluptates
                    sit assumenda.
                  </h3>
                  <p className="fst-italic">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <ul>
                    <li>
                      <i className="ri-check-double-line"></i> Ullamco laboris
                      nisi ut aliquip ex ea commodo consequat.
                    </li>
                    <li>
                      <i className="ri-check-double-line"></i> Duis aute irure
                      dolor in reprehenderit in voluptate velit.
                    </li>
                    <li>
                      <i className="ri-check-double-line"></i> Ullamco laboris
                      nisi ut aliquip ex ea commodo consequat. Duis aute irure
                      dolor in reprehenderit in voluptate trideta storacalaperda
                      mastiro dolore eu fugiat nulla pariatur.
                    </li>
                  </ul>
                  <p>
                    Ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum
                  </p>
                </div>
                <div className="col-lg-6 order-1 order-lg-2 text-center">
                  <img
                    src="assets/l2/features-1.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div
              className={`tab-pane ${
                activeTab === "tab-2" ? "active show" : ""
              }`}
              id="tab-2"
            >
              <div className="row">
                <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                  <h3>
                    Neque exercitationem debitis soluta quos debitis quo
                    mollitia officia est
                  </h3>
                  <p>
                    Ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum
                  </p>
                  <p className="fst-italic">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <ul>
                    <li>
                      <i className="ri-check-double-line"></i> Ullamco laboris
                      nisi ut aliquip ex ea commodo consequat.
                    </li>
                    <li>
                      <i className="ri-check-double-line"></i> Duis aute irure
                      dolor in reprehenderit in voluptate velit.
                    </li>
                    <li>
                      <i className="ri-check-double-line"></i> Provident
                      mollitia neque rerum asperiores dolores quos qui a. Ipsum
                      neque dolor voluptate nisi sed.
                    </li>
                    <li>
                      <i className="ri-check-double-line"></i> Ullamco laboris
                      nisi ut aliquip ex ea commodo consequat. Duis aute irure
                      dolor in reprehenderit in voluptate trideta storacalaperda
                      mastiro dolore eu fugiat nulla pariatur.
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 order-1 order-lg-2 text-center">
                  <img
                    src="assets/l2/features-2.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div
              className={`tab-pane ${
                activeTab === "tab-3" ? "active show" : ""
              }`}
              id="tab-3"
            >
              <div className="row">
                <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                  <h3>
                    Voluptatibus commodi ut accusamus ea repudiandae ut autem
                    dolor ut assumenda
                  </h3>
                  <p>
                    Ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum
                  </p>
                  <ul>
                    <li>
                      <i className="ri-check-double-line"></i> Ullamco laboris
                      nisi ut aliquip ex ea commodo consequat.
                    </li>
                    <li>
                      <i className="ri-check-double-line"></i> Duis aute irure
                      dolor in reprehenderit in voluptate velit.
                    </li>
                    <li>
                      <i className="ri-check-double-line"></i> Provident
                      mollitia neque rerum asperiores dolores quos qui a. Ipsum
                      neque dolor voluptate nisi sed.
                    </li>
                  </ul>
                  <p className="fst-italic">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
                <div className="col-lg-6 order-1 order-lg-2 text-center">
                  <img
                    src="assets/l2/features-3.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div
              className={`tab-pane ${
                activeTab === "tab-4" ? "active show" : ""
              }`}
              id="tab-4"
            >
              <div className="row">
                <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                  <h3>
                    Omnis fugiat ea explicabo sunt dolorum asperiores sequi
                    inventore rerum
                  </h3>
                  <p>
                    Ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum
                  </p>
                  <p className="fst-italic">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <ul>
                    <li>
                      <i className="ri-check-double-line"></i> Ullamco laboris
                      nisi ut aliquip ex ea commodo consequat.
                    </li>
                    <li>
                      <i className="ri-check-double-line"></i> Duis aute irure
                      dolor in reprehenderit in voluptate velit.
                    </li>
                    <li>
                      <i className="ri-check-double-line"></i> Ullamco laboris
                      nisi ut aliquip ex ea commodo consequat. Duis aute irure
                      dolor in reprehenderit in voluptate trideta storacalaperda
                      mastiro dolore eu fugiat nulla pariatur.
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 order-1 order-lg-2 text-center">
                  <img
                    src="assets/l2/features-4.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="services" className="services section-bg pt-5">
        <div className="container" data-aos="fade-up">
          <div className="row" data-aos="fade-up" data-aos-delay="200">
            <div className="col-md-6">
              <div className="icon-box">
                <i>
                  <FontAwesomeIcon icon={faLaptop} className="icon" />
                </i>
                <h4>
                  <a href="#">Lorem Ipsum</a>
                </h4>
                <p>
                  Voluptatum deleniti atque corrupti quos dolores et quas
                  molestias excepturi sint occaecati cupiditate non provident
                </p>
              </div>
            </div>

            <div className="col-md-6 mt-4 mt-md-0">
              <div className="icon-box">
                <i>
                  {" "}
                  <FontAwesomeIcon icon={faChartBar} className="icon" />
                </i>
                <h4>
                  <a href="#">Dolor Sitema</a>
                </h4>
                <p>
                  Minim veniam, quis nostrud exercitation ullamco laboris nisi
                  ut aliquip ex ea commodo consequat tarad limino ata
                </p>
              </div>
            </div>

            <div className="col-md-6 mt-4 mt-md-0">
              <div className="icon-box">
                <i>
                  <FontAwesomeIcon icon={faLightbulb} className="icon" />
                </i>

                <h4>
                  <a href="#">Sed ut perspiciatis</a>
                </h4>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat nulla pariatur
                </p>
              </div>
            </div>

            <div className="col-md-6 mt-4 mt-md-0">
              <div className="icon-box">
                <i>
                  <FontAwesomeIcon icon={faBriefcase} className="icon" />
                </i>
                <h4>
                  <a href="#">Nemo Enim</a>
                </h4>
                <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum
                </p>
              </div>
            </div>

            <div className="col-md-6 mt-4 mt-md-0">
              <div className="icon-box">
                <i>
                  <FontAwesomeIcon icon={faCheckSquare} className="icon" />
                </i>

                <h4>
                  <a href="#">Magni Dolore</a>
                </h4>
                <p>
                  At vero eos et accusamus et iusto odio dignissimos ducimus qui
                  blanditiis praesentium voluptatum deleniti atque
                </p>
              </div>
            </div>

            <div className="col-md-6 mt-4 mt-md-0">
              <div className="icon-box">
                <i>
                  {" "}
                  <FontAwesomeIcon icon={faClock} className="icon" />
                </i>
                <h4>
                  <a href="#">Eiusmod Tempor</a>
                </h4>
                <p>
                  Et harum quidem rerum facilis est et expedita distinctio. Nam
                  libero tempore, cum soluta nobis est eligendi
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServicesSection;
