import AboutUsFeatures from "../Common/AboutUs/AboutUsFeatures";
import AboutContent from "../Common/Hero/AboutContent";
import UserLogin from "../Common/Login/UserLogin";
import Registration from "../Common/Registration/Registration";
import ServicesInfo from "../Common/ServicesInfo/ServicesInfo";

import LandingpageTwo from "../Components/LandingPage/LandingpageTwo";

const PublicRoutes = [
  {
    path: "/",
    exact: true,
    layout: LandingpageTwo,
    element: <LandingpageTwo />,
  },
  {
    path: "/login",
    exact: true,
    layout: UserLogin,
    element: <UserLogin />,
  },
  {
    path: "/Registration",
    exact: true,
    layout: Registration,
    element: <Registration />,
  },
  {
    path: "/AboutUs",
    exact: true,
    layout: AboutUsFeatures,
    element: <AboutContent />,
  },
  {
    path: "/Services-Info",
    exact: true,
    layout: ServicesInfo,
    element: <ServicesInfo />,
  },
  {
    path: "/organization",
    exact: true,
    layout: ServicesInfo,
    element: <ServicesInfo />,
  },
  {
    path: "/individuals",
    exact: true,
    layout: ServicesInfo,
    element: <ServicesInfo />,
  },
];

export default PublicRoutes;
