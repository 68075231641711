import React from "react";
import Footer from "../../Common/Footer/Footer";
import Header from "../../Common/Header/Header";
import HeroSection from "../../Common/Hero/HeroSection";
import ClientsSlider from "../../Common/ClientsSlider/ClientsSlider";

import ServicesSection from "../../Common/Hero/ServicesSection";
import CallSection from "../../Common/Hero/CallSection";
import TeamSection from "../../Common/Hero/TeamSection";
import ContactSection from "../../Common/Hero/ContactSection";
import TestimonialSection from "../../Common/Hero/TestimonialSection";
import AboutSection from "../../Common/Hero/AboutSection";
import AboutBoxes from "../../Common/Hero/AboutBoxes";
const LandingpageTwo = () => {
  return (
    <div>
      <Header />
      <HeroSection />
      <AboutSection />
      <ClientsSlider />
      {/* <AboutBoxes/> */}
      <ServicesSection />
      <CallSection />
      {/* <TeamSection /> */}
      <ContactSection />
      <TestimonialSection />
      <Footer />
    </div>
  );
};

export default LandingpageTwo;
