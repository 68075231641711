import React from "react";
import { PureIncrement } from "pure_counter";
import "./AboutSection.css";
import { MdArrowOutward } from "react-icons/md";
// import { motion } from "framer-motion";
const AboutSection = () => {
  return (
    <section id="about" className="about ">
      <div className="container" data-aos="fade-up">
        <div className="row justify-content-center">
          <div className="col">
            <div className="row justify-content-center">
              <div className="outer col-sm-12  col-md-5  col-lg-3">
                <div className="count-box">
                  <PureIncrement
                    start={0}
                    end={100}
                    duration={2}
                    className="purecounter"
                  />
                  <div className="number-text">
                    <p>Happy Clients </p>
                  </div>
                </div>
              </div>

              <div className="outer col-sm-12  col-md-5 col-lg-3">
                <div className="count-box">
                  <PureIncrement
                    start={0}
                    end={200}
                    duration={2}
                    className="purecounter"
                  />
                  <div className="number-text">
                    <p>Curated Learning Offerings & Solutions</p>
                  </div>
                </div>
              </div>

              <div className="outer col-sm-12  col-md-5 col-lg-3">
                <div className="count-box">
                  <PureIncrement
                    start={0}
                    end={25}
                    duration={3}
                    className="purecounter"
                  />
                  <div className="number-text">
                    <p>Years in the corporate learning space</p>
                  </div>
                </div>
              </div>

              <div className="outer col-sm-12  col-md-5 col-lg-3">
                <div className="count-box">
                  <PureIncrement
                    start={0}
                    end={6}
                    duration={4}
                    className="purecounter"
                  />
                  <div className="number-text">
                    <p>Lives impacted through immersive learning experiences</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="aboutus-content row justify-content-center pt-5">
          <div
            className="col-lg-6 video-box d-flex align-items-center p-1"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            <video width="640" height="auto" controls>
              <source
                src="assets/Y2meta.app-Welcome to NeuCode Family-(720p).mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>

          <div className="col-lg-6 pt-5 pt-lg-0 align-items-center content">
            <h3 className=" text-center m-5 bold ">Why NeuCode?</h3>

            <p className="about-head  fs-4">
              <i className="ri-check-double-line "></i> Customized & Tech
              enabled Learning Solutions
            </p>
            <ul>
              <li>
                We understand that every organization has unique needs. Our
                learning solutions are highly customizable and tech-enabled to
                align with your specific business goals and challenges, ensuring
                maximum relevance and impact​.
              </li>
            </ul>
            <p className="about-head fs-4">
              <i className="ri-check-double-line"></i> Deep & Immersive Content
              Design
            </p>
            <ul>
              <li>
                NeuCode places a strong emphasis on developing emotional
                intelligence and growth mindset. We utilize latest researched
                concepts from Neuroscience, NLP, Transactional Analysis,
                Emotional intelligence & Org/Behavioral/Social psychology.
              </li>
            </ul>

            <p className="about-head fs-4">
              <i className="ri-check-double-line"></i> New-Age Methodologies
            </p>
            <ul>
              <li>
                We continuously thrive in the changing landscape of learning, We
                design immersive learning experiences using pedagogies like
                Lego, Art, Dance, Music, Cooking, Storytelling, Games, Movies to
                Simulation based, Human Lab based learnings.
              </li>
            </ul>

            <p className="about-head fs-4">
              <i className="ri-check-double-line"></i> Measuring & Reporting the
              Impact
            </p>
            <ul>
              <li>
                it a one-time session or a journey program, we have
                technology-based solutions to help you increase your return on
                learning investment.
              </li>
            </ul>

            <p className="pulse about-head text-center fs-4">
              Finally! Our Speed of Response will SURPRISE you!
            </p>

            <div className="row justify-content-center">
              <div className="promo">
                <a href="/#contactSection" className="about-contact-butt">
                  contact-US
                  <span>
                    <MdArrowOutward />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- ======= About Boxes Section ======= --> */}
      {/* <section id="about-boxes" className="about-boxes mt-5">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div
              className="col-lg-4 col-md-6 d-flex "
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="card">
                <img
                  src="assets/l2/about-boxes-1.jpg"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-icon">
                  <i className="ri-brush-4-line"></i>
                </div>
                <div className="card-body">
                  <h5 className="card-title">
                    <a href="">Our Mission</a>
                  </h5>
                  <p className="card-text">
                    Lorem ipsum dolor sit amet, consectetur elit, sed do eiusmod
                    tempor ut labore et dolore magna aliqua. Ut enim ad minim
                    veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 d-flex "
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="card">
                <img
                  src="assets/l2/about-boxes-2.jpg"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-icon">
                  <i className="ri-calendar-check-line"></i>
                </div>
                <div className="card-body">
                  <h5 className="card-title">
                    <a href="">Our Plan</a>
                  </h5>
                  <p className="card-text">
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem doloremque laudantium, totam rem aperiam, eaque
                    ipsa quae ab illo inventore veritatis et quasi architecto
                    beatae vitae dicta sunt explicabo.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 d-flex "
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="card">
                <img
                  src="assets/l2/about-boxes-3.jpg"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-icon">
                  <i className="ri-movie-2-line"></i>
                </div>
                <div className="card-body">
                  <h5 className="card-title">
                    <a href="">Our Vision</a>
                  </h5>
                  <p className="card-text">
                    Nemo enim ipsam voluptatem quia voluptas sit aut odit aut
                    fugit, sed quia magni dolores eos qui ratione voluptatem
                    sequi nesciunt Neque porro quisquam est, qui dolorem ipsum
                    quia dolor sit amet.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </section>
  );
};

export default AboutSection;
